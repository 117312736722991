import icon1 from './1.png';
import icon2 from './2.png';
import icon3 from './3.png';
import icon4 from './4.png';
import icon5 from './5.png';
import icon6 from './6.png';
import icon7 from './7.png';
import icon8 from './8.png';

export default {
    icon1,
    icon2,
    icon3,
    icon4,
    icon5,
    icon6,
    icon7,
    icon8,
};