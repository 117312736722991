import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Home";
import ProductsPage from "./ProductsPage";
import OurStory from "./OurStory";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/our-story" element={<OurStory />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
