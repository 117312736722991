import { Input, Textarea, Button } from '@material-tailwind/react';
import type { InputStylesType } from "@material-tailwind/react";
import zero from '../assets/zero.jpg';

export default function SignUp(props: { ref: any }) {
    return (
        <div ref={props.ref} className="bg-cover w-full h-full flex flex-col justify-end items-end bg-center bg-no-repeat mt-20 py-20 sm:px-20 px-12" style={{ backgroundImage: `url(${zero})` }}>
            <div className="w-full flex flex-col sm:items-end">
                <div className="lg:w-2/6 md:w-1/2 bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
                    <p className='uppercase text-xl text-center mb-8 font-Lemon px-8'>
                        LET’S JOiN HANDS FOR A
                        BETTER TOMORROW
                    </p>
                    <div className="relative mb-4">
                        <Input type="text" variant='outlined' color='gray' label='Your Name' className='' />
                    </div>
                    <div className="relative mb-4">
                        <Input type="text" variant='outlined' color='gray' label='Your Designation' className='' />
                    </div>
                    <div className="relative mb-4">
                        <Input type="text" variant='outlined' color='gray' label='Your Organization' />
                    </div>
                    <div className="relative mb-4">
                        <Input type="email" variant='outlined' label='Your Email' color='gray' />
                    </div>
                    <div className="relative mb-4">
                        <Textarea variant='outlined' label='Your Message' color='gray' />
                    </div>
                    <Button className="bg-primary-blue w-1/2 font-normal font-Lemon text-sm" variant="filled" size="md" ripple={true}>
                        Get in touch
                    </Button>
                </div>
            </div>
        </div>
    );
}