import CenterJustifiedPara from "./CenterJustifiedPara";
// import icon1 from '../assets/icons/index';
import icons from "../assets/icons/index";

export default function Information() {
    const values = [22, 34, 26, 6, 12]
    const texts = ['Chronic Obstructive Pulmonary Disease', 'Stroke', 'Ischaemic Heart Disease', 'Tracheal Bronchus and Lung Cancer', 'Acute Lower Respiratory Infections in Children']
    // icons[' ']
    const iconsArray = [icons.icon1, icons.icon2, icons.icon3, icons.icon4, icons.icon5, icons.icon6, icons.icon7, icons.icon8]
    return (
        <div className="bg-white sm:px-12 sm:py-20 py-12 px-8 ">
            <div className="sm:w-3/5 mx-auto flex flex-col items-center justify-center ">
                <p className="sm:w-1/2 sm:text-3xl text-2xl text-center uppercase font-Lemon mb-2">iNDOOR AiR POLLUTiON iS
                    KiLLiNG YOU, SiLENTLY!</p>
                <CenterJustifiedPara text="According to WHO, every 9 out of 10 people breathe harmful air that
                    makes air pollution one of our biggest health threats. Globally, each
                    year indoor air pollution kills 3.2 million people prematurely, out of
                    which 237000 deaths are of children under the age of 5." />
                <div className="flex sm:flex-row sm:w-5/6 -mx-4 flex-col sm:mx-auto sm:gap-y-0 gap-y-8 justify-between sm:items-start items-center">
                    {values.map((value, index) => {
                        return (
                            <StatIcon value={value} text={texts[index]} key={index} icon={iconsArray[index]} />
                        );
                    })}
                </div>
                <p className="font-Lemon text-primary-blue text-[18px] uppercase sm:mt-8 text-center mt-8">
                    Breakdown of diseases caused by indoor air pollution
                </p>
            </div>
        </div>
    );
}

function StatIcon(props: { value: number, text: string, icon: any }) {
    const total = 100;
    const perRow = 10;
    const rows = Math.ceil(total / perRow);
    const cols = rows;
    return (
        <div className="flex flex-col sm:gap-y-2 gap-y-0 sm:mx-0 sm:w-1/5 w-3/4">
            <div className="w-full h-28 -mt-2 flex justify-center items-center">
                <img src={props.icon} className="w-full h-full object-contain sm:mr-7" alt="icon" />
            </div>
            <div className="flex flex-wrap items-center mx-auto w-full gap-[2px]">
                {[...Array(rows)].map((_, i) => {
                    return (
                        <div className="flex flex-row mx-auto w-full sm:gap-x-[2px] gap-x-2" key={i}>
                            {[...Array(cols)].map((_, j) => {
                                return (
                                    <div className="sm:w-2.5 sm:h-2.5 w-7 h-5" key={j}>
                                        <div className={`w-full h-full rounded-full ${i * perRow + j < props.value ? 'bg-primary-blue' : 'bg-gray-300'}`}></div>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
            <p className="sm:text-base font-Lemon mt-2">{props.value}%</p>
            <p className="sm:text-base pr-4 font-Open-Sans">{props.text}</p>
        </div>
    );
}