import Banner from "./components/Banner";
import Information from "./components/Information";
import Delivering from "./components/delivering";
import SliderInfo from "./components/Slider";
import Features from "./components/Features";
import SignUp from "./components/SignUp";
import Footer from "./components/Footer";
import { useEffect, useRef } from "react";

export default function Home() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const contactsRef = useRef(null);
    return (
        <div>
            <Banner ref={contactsRef} />
            <Information />
            <SliderInfo />
            <Features />
            <SignUp ref={contactsRef} />
            <Footer />
        </div>
    );
}