import icons from "../assets/icons/index";
export default function Features() {
    const iconsArray = [icons.icon1, icons.icon2, icons.icon3, icons.icon4, icons.icon5, icons.icon6, icons.icon7, icons.icon8]
    return (
        <div className="w-4/5 mx-auto my-20">
            <p className="text-center sm:w-3/5 text-xl mx-auto uppercase sm:text-3xl font-Lemon">
                WE EMPOWER ambitious brands achieve their net-zero targets
            </p>
            <div className="flex sm:items-start sm:flex-row flex-col items-start justify-between w-full mx-auto mt-8 font-Open-Sans sm:gap-x-5 sm:gap-y-0 gap-y-5 gap-x-0">
                <Feature iconsArray={iconsArray} text="Our products are good for you and the
            environment. They give you and the planet a better life." index={5} />
                <Feature iconsArray={iconsArray} text="With our low-carbon products, we help
                        brands achieve their net-zero targets." index={6} />
                <Feature iconsArray={iconsArray} text="Our products bring the Textile Industry
                        a step closer to sustainability." index={7} />
            </div>
        </div>
    );
}

function Feature(props: { iconsArray: any, text: string, index: number }) {
    return <div className="sm:text-base text-xs flex flex-col items-center text-center justify-center sm:w-1/3 -mr-2">
        <div className="w-[14.28%] pb-4 h-16">
            <img src={props.iconsArray[props.index]} alt="icon" className="w-full h-full" />
        </div>
        <p>
            {props.text}
        </p>
    </div>;
}
