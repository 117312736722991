import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
export default function Footer() {
    return (
        <div className="flex sm:flex-row flex-col justify-between w-full sm:py-20 py-8 sm:px-20 px-12 sm:gap-x-8 gap-x-0 sm:gap-y-0 gap-y-4">
            <div className="sm:w-3/5 flex sm:flex-row flex-col sm:gap-x-8 gap-x-0 sm:gap-y-0 gap-y-4">
                <div className="flex flex-col items-start justify-center sm:w-1/2">
                    <img src={logo} alt="logo" className="w-48" />
                    <p className="my-4 w-2/3">
                        We help brands empower their audience to live a safer and sustainable life.
                    </p>
                </div>
                <div className="flex flex-col items-start justify-start sm:w-1/2">
                    <p className="uppercase font-Lemon">Address:</p>
                    <p className="mb-2 w-4/5">
                        12A, Second Floor, Hauz Khas Village, Delhi, India-110016
                    </p>
                    <p className="uppercase font-Lemon">Email:</p>
                    <p className="mb-2">
                        <a href="mailto:info@truetomorrow.in">info@truetomorrow.in</a>
                    </p>
                    <p className="uppercase font-Lemon">Phone:</p>
                    <p className="mb-2">
                        +91-9563099819
                    </p>
                </div>
            </div>
            <div className="flex flex-col items-start justify-between sm:w-2/5">
                <div className="flex flex-col w-full">
                    <p className="uppercase font-Lemon">subscribe to our newsletter</p>
                    <p className="">For product announcements and exclusive insights</p>
                    <div className="my-2 flex outline-2 outline-primary-blue outline rounded-lg p-0">
                        <input type="text" className="w-full px-4 py-0 rounded-lg focus:outline-none" placeholder="Your email address" />
                        <button className="border-primary-blue bg-primary-blue text-white px-4 py-2 rounded-lg m-0">Subscribe</button>
                    </div>
                </div>
                <div className="flex flex-wrap justify-between w-full sm:text-base text-sm uppercase font-Lemon">                
                    <Link to="/">
                        Home
                    </Link>
                    <Link to="/our-story">
                        Our story
                    </Link>
                    <Link to="products">
                        Product
                    </Link>
                    <a href="#">
                        Contact Us
                    </a>
                </div>
            </div>
        </div>
    );
}