
import CenterJustifiedPara from "./components/CenterJustifiedPara";
import Footer from "./components/Footer";
import NavigationBar from "./components/Navbar";
import { useEffect } from "react";
import prod1 from "./assets/prod1.jpg";
import prod2 from "./assets/prod2.jpg";
export default function ProductsPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const productImages = [prod1, prod2];
    const productNames = [
        "home haven",
        "le noir",
    ];
    return (
        <>
            <div className="flex justify-center flex-col items-center px-12 pt-4">
                <NavigationBar selected="Product" />
                <div className="flex justify-center flex-col items-center w-3/5">
                    <p className="text-4xl text-center w-2/3 mt-10 mb-2  font-Lemon">the world of "zero"</p>
                    <CenterJustifiedPara text="ZERO is a breakthrough range of air-purifying home textiles which apart from serving its traditional purpose, also brings in a nobel solution to the problem of
                    indoor air pollution."
                    />
                    <CenterJustifiedPara text="It purifies pollutants in the air like volatile organic compounds (VOCs),
formaldehydes, benzene, aldehydes, carbon dioxide and nitrogen oxide. The
purification requires no electricity and uses only natural or artificial light making
it your most eco-friendly, cost-efficient and hassle free air purification experience." />
                    <CenterJustifiedPara text="ZERO as the name suggets, comes from the word “Net-Zero”, indicating the need
for a drastic change to prevent climate catastrphy. It acknowledges all
individuals, companies and countries in their journey towards Net Zero by 2050." />
                    <div className="my-10 w-3/4">
                        {productImages.map((image, index) => productTile(productNames[index], image))}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

function productTile(name: string, image: any) {
    return (
        <div className="flex flex-col items-center text-white justify-end bg-cover bg-bottom w-full h-80 my-4 py-20 space-y-2" style={{ backgroundImage: `url(${image})` }}>
            <p className="text-2xl text-center font-Lemon">{name}</p>
            <a href="#" className="text-center underline font-Open-Sans">Request Catalogue</a>
        </div>
    );
}