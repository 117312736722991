import bg from "../assets/bg.gif";
import NavigationBar from "./Navbar";
import { Button } from "@material-tailwind/react";
import { Link } from "react-router-dom";

export default function Banner(props: {ref : any}) {
    return (
        <div className="h-screen bg-center px-12 pb-20 bg-cover flex justify-between bg-opacity- bg-white items-center flex-col bg-no-repeat"
        style={{ backgroundImage: `url(${bg})` }}
        >
            <NavigationBar selected="Home" />
            <div className="flex flex-col sm:w-2/5 items-center space-y-8">
                <p className="sm:text-3xl text-xl text-center text-black uppercase font-Lemon font-">HOME TEXTiLES THAT HELP YOU <br />BREATHE BETTER AiR</p>
                <div className="flex sm:gap-x-9 gap-x-4 w-full mx-auto justify-center text-xs">
                    <Link to='/our-story' className="bg-primary-blue text-center hover:drop-shadow-lg text-white px-4 py-2 rounded-lg font-Lemon w-1/3">
                        Know More
                    </Link>
                    <button className="bg-transparent text-primary-blue outline outline-2 hover:bg-primary-blue hover:text-white outline-primary-blue px-4 py-2 rounded-lg font-Lemon w-1/3" onClick={() => props.ref.current.scrollIntoView({ behavior: 'smooth' })}>
                        Partner With Us
                    </button>
                </div>
            </div>
        </div>
    );
}