import logo from '../assets/logo.png';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Navbar(props: { selected: string; }) {
  const [selected, changeSelected] = useState(props.selected);
  const [navbarOpen, setNavbarOpen] = useState(false);
  function NavTextElement(props: { text: string; isSelected: any; pathname: string; }) {
    return (
      <div>
        <Link
          to={
            {
              pathname: props.pathname == null ? '/' : props.pathname,
            }
          }
        >
          <button onClick={() => { }} className={` text-sm uppercase ${props.isSelected ? "font-normal text-primary-blue" : "font-normal"}`}>{props.text}</button>
        </Link>
      </div>
    );

  }

  return (
    <>
      <div className="w-full flex items-center justify-between px-10p py-4 font-Lemon">

        <Link to={{ pathname: '/' }} >
          <div className="flex items-center">
            <img src={logo} alt="" className="w-40" />
          </div>
        </Link>
        <div className="flex lg:flex-row flex-col sm:items-start items-center sm:justify-end sm:space-x-10 sm:space-y-0 space-y-4 justify-between">
          <NavTextElement text="Home" isSelected={selected === 'Home'} pathname="/" />
          <NavTextElement text="Our Story" isSelected={selected === 'Our Story'} pathname="/our-story" />
          <NavTextElement text="Product" isSelected={selected === 'Product'} pathname="/products" />
          <NavTextElement text="Contact Us" isSelected={selected === 'Contact us'} pathname="/about" />
        </div>

      </div>
    </>
  );
}