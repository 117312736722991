import CenterJustifiedPara from "./components/CenterJustifiedPara";
import Footer from "./components/Footer";
import NavigationBar from "./components/Navbar";
import { useEffect } from "react";
import raheli from "./assets/raheli.jpg";
export default function OurStory() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="flex justify-center flex-col items-center px-12 pt-4 pb-8">
                <NavigationBar selected="Our Story" />
                <div className="flex justify-center flex-col items-center w-3/5">
                    <p className="text-4xl text-center w-2/3 mt-10 mb-2  font-Lemon">access to clean air
                        shouldn’t be a luxury</p>
                    <CenterJustifiedPara text="Air is the fundamental building block, apart from which life would not exist at all.
We all need clean air to live, grow and thrive."
                    />
                    <p className="text-[18px] text-justify my-2 sm:w-3/4 font-Open-Sans" style={{ textAlignLast: "center", lineHeight: "26px" }}>
                        As stated by WHO, 99% of the global population breathes air that is harmful and
                        dirty, making air pollution one of our biggest health threats. It is a global
                        borderless crisis which requires multi-faceted solution at local, national and
                        global level.
                    </p>
                    <div className="bg-black w-[90%] h-80 mt-14 mb-20"></div>
                    <p className="text-4xl text-center w-3/4 mt-20 mb-2 font-Lemon">creating a better tomorrow
                        for mankind</p>
                    <CenterJustifiedPara text="True Tomorrow is a low-carbon home textile startup that began it’s journey in
2022 in India with the sole mission of “creating a better tomorrow for mankind”.
We believe that wellness is an important aspect of our lives and it starts from
home."
                    />
                    <p className="text-[18px]  text-justify mt-2 mb-4 sm:w-3/4 font-Open-Sans" style={{ textAlignLast: "center", lineHeight: "26px" }}>
                        Developed by designers and innovators, True Tomorrow’s uniqueness lies in it’s
                        functionality to tackle the present environmnetal crises to deliver wellness within
                        our sanctuaries.
                    </p>
                    <p className="text-primary-blue mt-5 mb-4 w-[77.5%] font-Lemon text-center text-[18px] mx-auto text-base">
                        We pick up where others leave, we build on, we reinvent
                        and sometimes even take that extra step to
                        serve our mission.
                    </p>
                    <p className="text-4xl text-center w-1/2 mt-20 mb-8 font-Lemon">
                        founder's vision
                    </p>
                </div>
                <div className="w-3/5 ">
                    <div className="w-[90%] mx-auto flex flex-col items-center justify-center mt font-Open-Sans">
                        <div className="w-full flex gap-x-10">
                            <div className="w-2/5 h-80" style={{ backgroundImage: `url(${raheli})`, backgroundSize: "cover", backgroundPosition: "center" }}></div>
                            <div className="w-4/5 flex flex-col justify-center items-center">
                                <p className="text-base font-Lemon text-left text-primary-blue w-full mb-4">
                                    RAHELI SARKAR, FOUNDER
                                </p>
                                {/* <p className="text-xl text-justify w-full my-4">
                                    Residing in Delhi and while working very closely with the
                                    textile industry for several years, Raheli realised two things.
                                </p> */}
                                <p className="text-[18px] text-justify w-full my-4">
                                    First, clean air is a luxury that almost nobody can afford.
                                    Second, the textile industry is one of the major contributers
                                    of global carbon emissions.
                                </p>
                                <p className="text-[18px] text-justify w-full my-4">
                                    With True Tomorrow, Raheli intends to make clean air
                                    accessable to everyone in an economical and seamless
                                    manner via textiles, a omnipresent material that is a part of
                                    our everyday lives, everywhere.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}