import { useState } from "react";
import furniture from "../assets/furniture.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button } from "@material-tailwind/react";
import CenterJustifiedPara from "./CenterJustifiedPara";
import furniture1 from "../assets/slider/1.jpg";
import furniture2 from "../assets/slider/2.jpg";
import furniture3 from "../assets/slider/3.jpg";
import furniture4 from "../assets/slider/4.jpg";

export default function SliderInfo() {
    const length = 4;
    const [hoverArray, setHoverArray] = useState(Array.from({ length }, () => false));
    const images = [furniture1, furniture2, furniture3, furniture4];
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 4,
        className: "w-[96%] mx-auto gap-x-4 py-0 flex items-center justify-center",
        leftArrow: <div className="w-0 h-0 hidden"></div>,
        rightArrow: <div className="w-0 h-0 hidden"></div>,
        pauseOnHover: false,
    };
    return (
        <>
            <div className="flex flex-col items-center justify-center sm:w-full sm:mx-auto mx-4 my-20">
                <div className="flex flex-col items-center justify-center sm:w-3/5 pb-2">
                    <p className="sm:text-4xl text-xl text-center sm:w-1/2 uppercase mb-2 leading-[0px] font-Lemon">INTRODUCING ZERO</p>
                    <CenterJustifiedPara text="Planet's most promising air-purifying home textiles that purify your indoor spaces in an eco-friendly, cost-efficient and seamless manner.
                    " />
                </div>
                {/* <div className="w-full flex items-center justify-center">

                    <Slider {...settings}>
                        {Array.from({ length }, (_, i) => (
                            <div className="flex items-center justify-center relative w-1/3 -mt-1 py-6 mx-16" onMouseEnter={() => setHoverArray(Array.from({ length }, (_, j) => i === j))} onMouseLeave={() => setHoverArray(Array.from({ length }, () => false))}>
                                <img src={furniture} alt="furniture" className="w-11/12 mx-auto transition-all duration-500 hover:scale-110 hover:opacity-100 opacity-50" />
                                <Button className={`bg-primary-blue absolute top-[80%] left-[28%] font-Lemon ${hoverArray[i] ? "opacity-100" : "opacity-0"} transition-all duration-500`} variant="filled" size="md" ripple={true}>
                                    View Collection
                                </Button>
                            </div>
                        ))}
                    </Slider>

                </div> */}
                <div className="overflow-x-scroll flex sm:px-8 sm:gap-x-10 gap-x-5 sm:h-[525px] items-center w-full scrollbar-hide">
                    {Array.from({ length }, (_, i) => (

                        <div className="block h-[500px] w-80 mx-auto transition-all duration-500 hover:scale-105 image-shadow hover:opacity-100 opacity-50 drop-shadow-lg " onMouseEnter={() => setHoverArray(Array.from({ length }, (_, j) => i === j))} onMouseLeave={() => setHoverArray(Array.from({ length }, () => false))} style={{ backgroundImage: `url(${images[i % 4]})`, backgroundSize: "cover", backgroundPosition: "center" }}>
                            <div className="sm:w-96 w-80"></div>
                            <button className={`bg-primary-blue absolute top-[88%] font-light left-[30%] font-Lemon ${hoverArray[i] ? "opacity-100" : "opacity-0"} transition-all duration-500 text-white px-4 rounded-lg py-2 text-xs`}>
                                {i % 4 === 3 || i % 4 === 2 ? "Coming Soon" : "View Collection"}
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}